import React from 'react'
import { Container, Eyebrow, PrimaryButton } from '../../styles'
import {
    HeroElement,
    HeroTitle,
    HeroControls,
    HeroContentWrapper,
    HeroScroll,
    VideoWrapper,
} from './HeroStyles'
import BannerVideo from '../../videos/pexels-c-technical-6275796.mp4'

const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const buttonContainer = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.12,
          },
    }
}

const button = {
    initial: {
        opacity: 0,
        y: 10
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            ...transition
        },
    }
}

const Hero = () => {
    return (
        <HeroElement
            large
            role="banner"
        >
            <VideoWrapper>
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={BannerVideo} type={`video/mp4`} />
                </video>
            </VideoWrapper>
            <Container flex fullHeight column justifyCenter alignCenter>
                <HeroContentWrapper>
                    <Eyebrow
                        centered
                        large
                        initial={{opacity: 0, y: 20 }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { delay: 0.35, ...transition}
                        }}
                    >
                        Focused soley on child sex abuse
                    </Eyebrow>
                    <HeroTitle
                        initial={{ opacity: 0, y: 30, ...transition }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { delay: 0.45, ...transition}
                        }}
                    >
                        Justice For Victims of Child Sex Abuse
                    </HeroTitle>
                    <HeroControls
                        variants={buttonContainer}
                        initial="initial"
                        animate="animate"
                    >
                        <PrimaryButton
                            to="/contact-us/"
                            modifiers={['large', 'greenSecondary']}
                            variants={button}
                        >
                            Get help now
                        </PrimaryButton>
                    </HeroControls>
                </HeroContentWrapper>
            </Container>
            <HeroScroll
                initial={{
                    opacity: 0,
                    y: 16,
                    x: "-50%"}}
                animate={{
                    opacity: 1,
                    y: 0,
                    x: "-50%",
                    transition: { delay: 0.9, ...transition}
                }}
            >
                Scroll
            </HeroScroll>
        </HeroElement>
    )
}

export default Hero
