import styled from 'styled-components'
import { defaultTheme, PrimaryButton } from '../../styles'

export const ImageTextBlockElement = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    
    @media(min-width: 900px) {
        flex-direction: row;
        padding: 80px 0 120px;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    @media(min-width: 900px) {
        width: 50%;
    }

    &:before {
        @media(min-width: 900px) {
            position: absolute;
            left: -40px;
            top: -40px;
            display: block;
            height: calc(100% + 80px);
            width: 100%;
            background: ${defaultTheme.darkBlue};
            content: "";
            z-index: -1;
        }

        @media(min-width: 1400px) {
            max-width: 600px;
        }
    }

    .gatsby-image-wrapper {
        height: 100%;
        
        @media(min-width: 1400px) {
            height: auto;
        }
    }

    img {
        @media(min-width: 1400px) {
            max-width: 600px;
        }
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    
    @media(min-width: 900px) {
        padding: 0 0 0 64px;
        width: 50%;
    }

    h2 {
        margin-top: 32px;

        @media (min-width: 900px) {
            margin-top: 0;
        }
    }

    p {
        color: ${defaultTheme.darkBlue};
        font-size: 20px;
        font-weight: 300;
        line-height: 1.618;

        @media(min-width: 900px) {
            font-size: 24px;
        }
    }

    ${PrimaryButton} {
        margin-top: 24px;
        width: 100%;

        @media(min-width: 700px) {
            width: auto;
        }
    }
`;