import React from 'react'
import { Container, PrimaryButton } from '../../styles'
import {
    ImageTextBlockElement,
    ImageWrapper,
    TextWrapper
} from './ImageTextBlockStyle'
import Image from 'gatsby-image'

const ImageTextBlock = ({heading, imageSrc, bodyCopy, buttonLabel, buttonUrl}) => {
    return (
        <Container>
            <ImageTextBlockElement>
                <ImageWrapper>
                    <Image
                        fluid={imageSrc} 
                        alt=""
                    />
                </ImageWrapper>
                <TextWrapper>
                    {heading && (
                        <h2>{heading}</h2>
                    )}
                    {bodyCopy && (
                        <div dangerouslySetInnerHTML={{__html: bodyCopy}} />
                    )}
                    {buttonUrl && (
                        <PrimaryButton to={ buttonUrl } modifiers={['large', 'green']}>
                            { buttonLabel }
                        </PrimaryButton>
                    )}
                </TextWrapper>
            </ImageTextBlockElement>
        </Container>
    )
}

export default ImageTextBlock
