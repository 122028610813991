import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import ResultsSlider from "../components/ResultsSlider"
import ImageTextBlock from "../components/ImageTextBlock"
import PracticeAreaSlider from "../components/PracticeAreaSlider"
import TestimonialsSlider from '../components/TestimonialsSlider'
import Counters from "../components/Counters"
import TextSection from "../components/TextSection"
import CountersSecond from "../components/Counters/CountersSecond"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {absolutePath: {regex: "/images/"}}) {
        nodes {
          name
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const allImages = data.allFile.nodes; 

  return (
    <Layout>
      <SEO
        title="Child Sex Abuse Attorney | Sexual Abuse Lawyer"
        metaDesc="Craig Charles Law is a boutique law firm focused solely on obtaining justice for 
        victims of California child sex abuse."
      />
      <Hero />

      <Counters blueBg />
      
      <TextSection bgColor={`#89a5cf`}>
        <h2>Our Philosophy</h2>
        <p>
          We see ourselves as more than just attorneys. Craig Charles Law provides each victim of California 
          child sex abuse with a holistic healing approach. In addition to obtaining maximum compensation for 
          each California sex abuse client, our firm also employs a team of mental health specialists and case 
          workers who help to provide healing and closure. We consider our clients family, and treat each child 
          sex abuse victim with the care, attention and compassion they deserve. Unlike large “mill” law firms, 
          each sex abuse client works directly with an attorney who is available during every step of litigation.
        </p>
      </TextSection>

      <CountersSecond blueBg />

      <PracticeAreaSlider />
      <TestimonialsSlider />
      <ResultsSlider />
      <ImageTextBlock
        imageSrc={allImages.find(node => node.name === "craig-charles-lawyer").childImageSharp.fluid}
        bodyCopy="<p>Craig Charles Law is a boutique law firm focused solely on obtaining justice for 
        victims of California child sex abuse. Our vast experience allows us to deliver top-notch results 
        and help sex abuse clients gain a sense of closure. We take an individual approach to each case, 
        ensuring that sex abuse victims receive the care they need and the compensation they deserve. If 
        you or a loved one is the victim of underage child sex abuse in California, let us help you on 
        your road to recovery. For a confidential, no-cost consultation, contact us today.</p>"
        buttonUrl="/about/"
        buttonLabel="About Craig Charles Law"
        heading={`Choose an experienced attorney who you can trust`}
      />
    </Layout>
  )
}

export default IndexPage
