import styled from 'styled-components'
import { defaultTheme } from '../../styles'

export const TestimonialsSliderElement = styled.div`
    padding: 40px 0;
    background: #89a5cf;
    color: ${defaultTheme.darkBlue};
    overflow-x: hidden;

    @media(min-width: 900px) {
        padding: 80px 0;
    }
`;

export const TestimonialsSliderHeader = styled.h2`

`;

export const TestimonialsSlide = styled.div`
    padding: 0 12px;

    @media(min-width: 900px) {
        padding: 0 48px;
    }

    blockquote {
        margin: 0;
    }
    
    p {
        font-family: Georgia;
        font-size: 20px;
        font-weight: 200;
        line-height: 1.618;

        @media(min-width: 900px) {
            font-size: 24px;
        }
    }
`;

export const TestimonialsAuthor = styled.figcaption`
    font-size: 22px;
    font-weight: 500;
    @media(min-width: 900px) {
        font-size: 24px;
    }
`;