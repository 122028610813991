import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Slider from "react-slick"
import {
    TestimonialsSliderElement,
    TestimonialsSliderHeader,
    TestimonialsSlide,
    TestimonialsAuthor
} from './TestimonialsSliderStyles'
import { Container } from '../../styles'

var settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const TestimonialsSlider = ({bgColor}) => {
    const data = useStaticQuery(graphql`
        {
            allTestimonialsJson(limit: 4) {
                nodes {
                    author
                    reviewBody
                    reviewSnippet
                }
            }
        }
    `)

    return (
        <TestimonialsSliderElement style={{backgroundColor: `${bgColor}`}}>
            <Container textCentered>
                <TestimonialsSliderHeader>
                    What Our Clients Are Saying
                </TestimonialsSliderHeader>
                <Slider {...settings}>
                    {data.allTestimonialsJson.nodes.map(review => {
                        return <TestimonialsSlide key={review.author}>
                            <blockquote dangerouslySetInnerHTML={{ __html: review.reviewSnippet }} />
                            <TestimonialsAuthor>
                                {review.author}
                            </TestimonialsAuthor>
                        </TestimonialsSlide>
                    })}
                </Slider>
            </Container>
        </TestimonialsSliderElement>
    )
}

export default TestimonialsSlider
