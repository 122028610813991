import styled from 'styled-components'
import {defaultTheme} from '../../styles'

export const ResultBlock = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin: 0 12px;
    padding: 40px 24px;
    background: ${defaultTheme.white};
    border-top: 6px solid ${defaultTheme.green};
    color: ${defaultTheme.darkBlue};

    @media(min-width: 700px) {
        margin: 0 16px;
    }

    @media(min-width: 1400px) {
        padding: 24px 20px 30px;
    }

    @media(min-width: 1600px) {
        padding: 32px 24px 28px;
    }

    @media(min-width: 1800px) {
        padding: 32px 28px 28px;
    }

    &.sidebar__result {
        margin: 0;
        min-height: 300px;
        background: ${defaultTheme.darkBlue};
        color: ${defaultTheme.white};
    }
`

export const ResultCourt = styled.p`
    display: inline-block;
    background: ${defaultTheme.green};
    color: ${defaultTheme.darkBlue};
    margin: 0 0 16px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.063em;
    text-transform: uppercase;

    @media(min-width: 900px) {
        margin: 0 0 24px;
    }
`;

export const ResultTitle = styled.h3`
    margin: 0 0 8px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.25;
`;

export const ResultsBody = styled.div`
    p {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.618;

        @media(min-width: 1200px) {
            /* font-size: 16px; */
        }

        @media(min-width: 1400px) {
            /* font-size: 18px; */
        }
    }
`;

export const ResultAmount = styled.h4`
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
`;