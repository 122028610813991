import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'

const ColumnModifiers = {
    hasCols: () => `
        .cols {
            @media (min-width: 750px) {
                display: flex;
                align-items: center;
            }
        }

        .imagecol {
            margin-bottom: 32px;
            @media (min-width: 750px) {
                margin-bottom: 0;
                padding-right: 40px;
                width: 40%;
            }
        }

        .textcol {
            @media (min-width: 750px) {
                width: 60%;
            }
        }
    `
}

export const TextSectionElem = styled.section`
    padding: 40px 0;

    @media(min-width: 900px) {
        padding: 80px 0;
    }

    p, li {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.618;
        @media(min-width: 900px) {
            max-width: 900px;
            font-size: 22px;
        }
    }

    ul {
        padding-left: 25px;
        list-style: disc;
        li {    
            margin-bottom: 16px;
        }
    }

    ${applyStyleModifiers(ColumnModifiers)}
`;