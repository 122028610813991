import React from 'react'
import CountUp from 'react-countup'
import { Container } from '../../styles'
import {
    BigNumber,
    Counter,
    CountersElm,
    CountersWrapper
} from './CountersStyles'

const CountersSecond = ({
    blueBg,
    env
}) => {

    const counterData = [
        {
            endValue: 5,
            caption: '1 in 5 females are the victim of underage sex abuse',
            suffix: '',
            duration: 0.5,
        },
        {
            endValue: 40,
            caption: '40% of all sex abuse crimes go unreported',
            suffix: '%',
            duration: 1,
        },
        {
            endValue: 20,
            caption: '1 in 20 males are the victim of underage sex abuse',
            suffix: '',
            duration: 0.75,
        },
    ];

    return (
        <CountersWrapper className={`${blueBg ? 'blue' : ''}`}>
            <Container>
                <CountersElm>
                    {counterData.map(counterItem => {
                        return (
                            <Counter>
                                <BigNumber>
                                    <CountUp
                                        start={0}
                                        end={counterItem.endValue}
                                        separator={`,`}
                                        duration={counterItem.duration}
                                        suffix={counterItem?.suffix}
                                    />
                                </BigNumber>
                                <p>
                                    {counterItem.caption}
                                </p>
                            </Counter>
                        );
                    })}
                </CountersElm>
            </Container>
        </CountersWrapper>
    )
}

export default CountersSecond
