import styled from 'styled-components'
import { defaultTheme } from '../../styles'

export const CountersElm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 0;

    @media (min-width: 900px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Counter = styled.div`
    text-align: center;

    @media (min-width: 900px) {
        width: 33.333%;
    }

    p {
        font-size: 20px;
        line-height: 1.5;
    }
`

export const CountersWrapper = styled.section`
    padding: 32px 0;

    @media(min-width: 900px) {
        padding: 56px 32px;
    }

    &.blue {
        background: ${defaultTheme.darkBlue};
        color: ${defaultTheme.white};
    }
`

export const BigNumber = styled.span`
font-weight: 700;
font-size: 72px;
display: block;
letter-spacing: 1px;
line-height: 60px;
position: relative;
margin: 0.5em 0;

@media (max-width: 700px) {
  font-size: 60px;
}
`